import React from 'react';
import { Container, Row, Col, List, BA } from 'bootstrap-4-react';
import logo from '../../media/footer-logo.png';
import phone from '../../media/footer_phone.svg';
import mail from '../../media/footer_mail.svg';
import marker from '../../media/footer_marker.svg';
import classNames from 'classnames';
import styles from '../../components/Footer/Footer.module.scss';

const Footer = () => {
    return (
        <footer className={styles.Footer}>
            <Container>
                <Row>
                    <Col col="12 md-4 lg-3">
                        <Row className="h-100" alignContent="between">
                            <Col col="12">
                                <img className={styles.logo} src={logo} alt="" />
                            </Col>
                            <Col col="12">
                                <div className={classNames(styles.copyright, 'd-none d-md-block')}>© 2023 ООО «Транс Тех»</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col col="12 md-8 lg-9">
                        <Row>
                            <Col col="12 lg-8 xl-8">
                                <div className={styles.subtitle}>Услуги</div>
                                <List className={styles.servicesList}>
                                    <List.Item>Отстой вагонов и формирование маршрутов на собственных  путях</List.Item>
                                    <List.Item>Организация перевозок грузов внутри России и на международных направлениях</List.Item>
                                    <List.Item>Сервис и ремонт подвижного состава</List.Item>
                                    <List.Item>Продажа/Закупка запасных частей железнодорожного подвижного состава. </List.Item>
                                    <List.Item>Разработка IT решений для модернизации и эффективности Железнодорожной деятельности</List.Item>
                                </List>
                            </Col>
                            <Col col="12 lg-4 xl-4">
                                <div className={styles.subtitle}>Контакты</div>
                                <List className={styles.contactsList}>
                                    <List.Item><img src={phone} alt="" /><BA href="tel:89618558988">+7 (961) 855-89-88</BA></List.Item>
                                    <List.Item><img src={mail} alt="" /><BA href="mailto:info@transtechgroup.ru">info@transtechgroup.ru</BA></List.Item>
                                    <List.Item><img src={marker} alt="" />Москва, Большая Серпуховская, 13, стр5</List.Item>
                                </List>
                                <div className={classNames(styles.copyright, 'd-md-none mt-5')}>© 2023 ООО «Транс Тех»</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
