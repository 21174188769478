import Header from './components/Header/Header';
import Intro from './components/Intro/Intro';
import ServicesList from './components/Services/Services';
import Features from './components/Features/Features';
import About from './components/About/About';
import Contacts from './components/Contacts/Contacts';
import Footer from './components/Footer/Footer';
import './App.scss';
import Modals from './components/Modals/Modals';


function App() {
  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <Intro />
        <ServicesList />
        <Features />
        <About />
        <Contacts />
        <Footer />
        <Modals />
      </div>
    </div>
  );
}

export default App;
