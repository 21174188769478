import React from 'react';
import { Container, Row, Col, Card, Button } from 'bootstrap-4-react';
import classNames from 'classnames';
import picture1 from '../../media/service1.png';
import picture2 from '../../media/service2.png';
import picture3 from '../../media/service3.png';
import picture4 from '../../media/service4.png';
import picture5 from '../../media/service5.png';
import styles from '../../components/Services/Services.module.scss';


const ServicesList = () => {
    return (
        <section className={styles.Services} id="services">
            <Container>
                <Row>
                    <Col col="12">
                        <h2 className={classNames(styles.title, "section-title")}>Услуги</h2>
                    </Col>
                </Row>
                <Row className={styles.list}>
                    <Col col="12 sm-6 xl-4">
                        <Card className={styles.card}>
                            <Card.Image className={styles.img} src={picture1} />
                            <Card.Body className={styles.body}>
                                <Card.Text>Отстой вагонов и формирование маршрутов на собственных ЖД путях по ключевым регионам России</Card.Text>
                                <Button as="a" link href="mailto:info@transtechgroup.ru">Оставить заявку<i className="arrow"></i></Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col col="12 sm-6 xl-4">
                        <Card className={styles.card}>
                            <Card.Image className={styles.img} src={picture2} />
                            <Card.Body className={styles.body}>
                                <Card.Text>Организация перевозок грузов по всей сети железных дорог России и на международных направлениях. </Card.Text>
                                <Button as="a" link href="mailto:info@transtechgroup.ru">Оставить заявку<i className="arrow"></i></Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col col="12 sm-6 xl-4">
                        <Card className={styles.card}>
                            <Card.Image className={styles.img} src={picture3} />
                            <Card.Body className={styles.body}>
                                <Card.Text>Сервис и ремонт подвижного состава.</Card.Text>
                                <Button as="a" link href="mailto:info@transtechgroup.ru">Оставить заявку<i className="arrow"></i></Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col col="12 sm-6 xl-4">
                        <Card className={styles.card}>
                            <Card.Image className={styles.img} src={picture4} />
                            <Card.Body className={styles.body}>
                                <Card.Text>Продажа/Закупка запасных частей железнодорожного подвижного состава.</Card.Text>
                                <Button as="a" link href="mailto:info@transtechgroup.ru">Оставить заявку<i className="arrow"></i></Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col col="12 sm-6 xl-4">
                        <Card className={styles.card}>
                            <Card.Image className={styles.img} src={picture5} />
                            <Card.Body className={styles.body}>
                                <Card.Text>Разработка IT решений для модернизации и эффективности железнодорожной деятельности.</Card.Text>
                                <Button as="a" link href="mailto:info@transtechgroup.ru">Оставить заявку<i className="arrow"></i></Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default ServicesList;
