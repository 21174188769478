import React, {useEffect, useRef, useLayoutEffect} from 'react';
import { Navbar, Nav, Collapse, Container, BA, BDiv } from 'bootstrap-4-react';
import logo from '../../media/logo.png';
import classNames from 'classnames';
import call from '../../media/call.svg';
import sms from '../../media/sms.svg';
import styles from './Header.module.scss';


const Header = () => {
    useEffect(() => {
        const list = document.getElementById('navbarSupportedContent');
        const navLinks = list.querySelectorAll('.nav-link');
        
        navLinks.forEach(item => {
            item.addEventListener('click', () => {
                if(item.closest('.navbar-collapse').classList.contains('show')) {
                    item.closest('.navbar-collapse').classList.remove('show');
                    document.querySelector('.navbar-toggler').classList.add('collpase');
                    document.querySelector('.navbar-toggler').setAttribute('aria-expanded', false)
                }
            });
        })
    }, [])

    const stickyHeader = useRef();

    useLayoutEffect(() => {
        const header = document.getElementById("header");
        let fixedTop = stickyHeader.current.offsetTop;

        const stickyHeaderEvent = () => {
            if (window.pageYOffset > fixedTop) {
                header.classList.add("sticky-header");
            } else {
                header.classList.remove("sticky-header");
            }
        };
        window.addEventListener("scroll", stickyHeaderEvent);
    }, []);
    

    return (
        <div className={styles.headerWrapper} id="header" ref={stickyHeader}>
            <Container>
                <header>
                    <Navbar expand="lg" light px="0">
                        <Navbar.Brand href="/" className={styles.logo}>
                            <img src={logo} alt="" />
                        </Navbar.Brand>
                        <BDiv className="d-flex flex-nowrap align-items-center order-lg-3">
                            <BDiv className={styles.info}>
                                <BDiv>
                                    <BA href="tel:89618558988" className={classNames(styles.primary, 'd-none d-sm-inline')}>+7 (961) 855-89-88</BA>
                                    <BA href="tel:89618558988" className="d-sm-none"><img src={call} alt="" /></BA>
                                </BDiv>
                                <BDiv>
                                    <BA href="mailto:info@transtechgroup.ru" className={classNames(styles.secondary, 'd-none d-sm-inline')}>info@transtechgroup.ru</BA>
                                    <BA href="mailto:info@transtechgroup.ru" className="d-sm-none"><img src={sms} alt="" /></BA>
                                </BDiv>
                            </BDiv>
                            <Navbar.Toggler target="#navbarSupportedContent">
                                <span></span>
                            </Navbar.Toggler>
                        </BDiv>
                        <Collapse navbar id="navbarSupportedContent">
                            <Navbar.Nav m="lg-auto" className={styles.nav}>
                                <Nav.Item active>
                                    <Nav.Link href="#services">Услуги</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="#about">О компании</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="#contacts">Контакты</Nav.Link>
                                </Nav.Item>
                            </Navbar.Nav>
                        </Collapse>
                    </Navbar>
                </header>
            </Container>
        </div>
    );
}

export default Header;
