import React from 'react';
import { Container, Row, Col } from 'bootstrap-4-react';
import picture from '../../media/about_picture.png';
import classNames from 'classnames';
import styles from '../../components/About/About.module.scss';


const About = () => {
    return (
        <section className={styles.About} id="about">
            <Container>
                <Row alignItems="center" className={styles.row}>
                    <Col col="12 lg-6 xl-6">
                        <img className={styles.picturePrimary} src={picture} alt="" />
                    </Col>
                    <Col col="12 lg-6 xl-6">
                        <h2 className={classNames(styles.title, "section-title")}>О компании</h2>
                        <div className={styles.content}>
                            <p>ООО “Транс Тех” обладает одним из крупнейшим в России комплексом по обслуживанию, формированию и отстою подвижного состава.  Находится непосредственно на Транссиб и примыкает к станционным путям. Имеет выгодное расположение и является узловой станцией, что позволяет производить быструю отправку и приёмку вагонов.</p>
                            <p>Мы сотрудничаем с крупнейшими владельцами подвижного состава в России, а также с главными игроками железнодорожных перевозок на рынке.</p>
                            <p>Благодаря большому опыту и профессионализму наших сотрудников решение всех задач выполняется оперативно и с максимальным качеством.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default About;
