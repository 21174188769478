import React from 'react';
import Bootstrap, { Modal, Button, BA, Row, Col, } from 'bootstrap-4-react';
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import close from '../../media/close-button.svg';
import check from '../../media/check.png'


const Modals = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm()
    
    const onSubmit = (data) => {
        console.log(data)

        reset({ 
            name: "",
            phone: "",
        })

        Bootstrap.modal('#requestModal', 'hide');
        Bootstrap.modal('#successModal', 'show');
    }

    const phoneValidate = (value) => {
        if(value.includes('_')) {
            return false
        } else {
            return true
        }
    }

    return (
        <>
            <Modal id="requestModal" fade>
                <Modal.Dialog>
                    <Modal.Content>
                        <Modal.Header>
                            <Modal.Title>
                                <strong>Заполните форму</strong>
                                <p>И мы перезвоним Вам в ближайшее время</p>
                            </Modal.Title>
                            <Modal.Close>
                                <img src={close} alt="" />
                            </Modal.Close>
                        </Modal.Header>
                        <Modal.Body>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='form-group'>
                                    <input 
                                        type="text" 
                                        placeholder="Имя" 
                                        className='form-control' 
                                        aria-invalid={errors.name ? "true" : "false"}
                                        {...register("name", { required: true, minLength: 2 })} />
                                    {errors.name?.type === 'required' && <span className='field-error'>Поле обязательное</span>}
                                    {errors.name?.type === 'minLength' && <span className='field-error'>Поле должно содержать не менее 2 букв</span>}
                                </div>
                                <div className='form-group'>
                                    <InputMask 
                                        placeholder="Номер телефона"
                                        className='form-control'
                                        type="tel"
                                        mask='+7(999)-999-99-99' 
                                        aria-invalid={errors.phone ? "true" : "false"}
                                        {...register("phone", { required: true, validate: value => phoneValidate(value) })}>
                                    </InputMask>
                                    {errors.phone?.type === 'required' && <span className='field-error'>Поле обязательное</span>}
                                    {errors.phone?.type === 'validate' && <span className='field-error'>Введите корректный номер телефона</span>}
                                </div>
                                <div className='form-group'>
                                    <div className="form-check">
                                        <label htmlFor="persdata" className="form-check-label">
                                            <input 
                                                id="persdata"
                                                type="checkbox" 
                                                className="form-check-input"
                                                defaultChecked
                                                aria-invalid={errors.persdata ? "true" : "false"}
                                                {...register("persdata", { required: true })} /><i></i>
                                                <span>Я согласен с <BA href="#">условиями обработки персональных данных</BA></span>
                                        </label>
                                        {errors.persdata?.type === 'required' && <div className='field-error'>Поле обязательное</div>}
                                    </div>
                                </div>
                                <Button primary className="w-100" type="submit">Отправить</Button>
                            </form>
                        </Modal.Body>
                    </Modal.Content>
                </Modal.Dialog>
            </Modal>
            <Modal id="successModal" fade>
                <Modal.Dialog>
                    <Modal.Content>
                        <Modal.Body>
                            <Row justifyContent="center">
                                <Col col="auto"><img style={{width: '100px'}} src={check} alt="" /></Col>
                            </Row>
                            <h5 className='text-center mt-3'><strong>Благодарим за обращение! <br/>В скором времени Вам перезвоним.</strong></h5>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button secondary outline data-dismiss="modal">Закрыть</Button>
                        </Modal.Footer>
                    </Modal.Content>
                </Modal.Dialog>
            </Modal>
        </>
    );
}

export default Modals;
