import React from 'react';
import { Container, Row, Col } from 'bootstrap-4-react';
import styles from '../../components/Features/Features.module.scss';
import picture1 from '../../media/feature_ico1.png';
import picture2 from '../../media/feature_ico2.png';
import picture3 from '../../media/feature_ico3.png';
import picture4 from '../../media/feature_ico4.png';
import picture5 from '../../media/feature_ico5.png';
import classNames from 'classnames';


const Features = () => {
    return (
        <section className={styles.Features}>
            <Container>
                <Row>
                    <Col col="12 md-2 lg-3" className={styles.picture}>
                    </Col>
                    <Col col="12 md-10 lg-9">
                        <div className={styles.content}>
                            <h2 className="section-title">Наши преимущества</h2>
                            <Row className={styles.cards}>
                                <Col col="12 md-6">
                                    <Row alignItems="center" className="h-100">
                                        <Col col="12">
                                            <div className={styles.card}>
                                                <div className={styles.header}>
                                                    <img src={picture1} alt=""/>
                                                </div>
                                                <p>Оперативность и высокое качество выполнения поставленных задач</p>
                                            </div>
                                            <div className={styles.card}>
                                                <div className={styles.header}>
                                                    <img src={picture2} alt=""/>
                                                </div>
                                                <p>Индивидуальный подход к каждому клиенту</p>
                                            </div>
                                            <div className={styles.card}>
                                                <div className={styles.header}>
                                                    <img src={picture3} alt=""/>
                                                </div>
                                                <p>Нацеленность на долгосрочное сотрудничество</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col col="12 md-6">
                                    <Row alignItems="center" className="h-100">
                                        <Col col="12">
                                            <div className={classNames(styles.card, 'mt-0 mt-sm-4')}>
                                                <div className={styles.header}>
                                                    <img src={picture4} alt=""/>
                                                </div>
                                                <p>Соблюдение сроков и быстрое согласование заявок на подачу и уборку вагонов</p>
                                            </div>
                                            <div className={classNames(styles.card, 'mb-sm-4')}>
                                                <div className={styles.header}>
                                                    <img src={picture5} alt=""/>
                                                </div>
                                                <p>Комфортные тарифы на обслуживание</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Features;
