import React from 'react';
import { Container, Row, Col, Button } from 'bootstrap-4-react';
import styles from '../Intro/Intro.module.scss';
import introPicture from '../../media/intro_picture.png';
import intro_mobile from '../../media/intro_mobile.jpg';


const Intro = () => {
    return (
        <section className={styles.Intro}>
            <Container>
                <Row alignItems="center">
                    <Col col="12 lg-6">
                        <div className={styles.content}>
                            <h1>Оперирование подвижным составом</h1>
                            <p><span className="primary-text">Оперативность</span> и <span className="primary-text">высокое качество</span> выполнения поставленных задач по отстою и оперированию подвижного состава </p>
                            {/* <Button primary data-toggle="modal" data-target="#requestModal">Оставить заявку</Button> */}
                            <Button primary as="a" href="mailto:info@transtechgroup.ru">Оставить заявку</Button>
                        </div>
                    </Col>
                    <Col col="12 lg-6">
                        <Row>
                            <Col>
                                <img className='d-none d-lg-block' src={introPicture} alt="" />
                                <img className='d-lg-none' src={intro_mobile} alt="" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Intro;
