import React from 'react';
import { Container, Row, Col, List, BA, Button } from 'bootstrap-4-react';
import phone from '../../media/phone.svg';
import mail from '../../media/mail.svg';
import marker from '../../media/marker.svg';
import { YMaps, Map, Placemark, ZoomControl } from '@pbe/react-yandex-maps';
import pdf from '../../media/transtech.pdf';
import styles from '../../components/Contacts/Contacts.module.scss';


const Contacts = () => {
    return (
        <section className={styles.Contacts} id="contacts">
            <Container>
                <Row className={styles.row}>
                    <Col col="12 lg-6">
                        <YMaps>
                            <Map className={styles.map} defaultState={{ 
                                center: [55.727734, 37.625918], 
                                zoom: 15,
                                controls: [],
                            }}>
                                <ZoomControl options={{ float: "right" }} />
                                <Placemark geometry={[55.727734, 37.625918]} />
                            </Map>
                        </YMaps>
                    </Col>
                    <Col col="12 lg-6">
                        <Row>
                            <Col col="12 xl-10" offset="xl-1">
                                <div className={styles.content}>
                                    <h2 className="section-title">Контакты</h2>
                                    <p className={styles.secondary}>Свяжитесь с нами любым удобным для Вас способом. Мы всегда рады ответить на Ваши вопросы!</p>
                                    <List className={styles.list}>
                                        <List.Item><img src={phone} alt="" /><BA href="tel:89618558988">+7 (961) 855-89-88</BA></List.Item>
                                        <List.Item><img src={mail} alt="" /><BA href="mailto:info@transtechgroup.ru">info@transtechgroup.ru</BA></List.Item>
                                        <List.Item><img src={marker} alt="" />Москва, Большая Серпуховская, 13, стр5</List.Item>
                                        <div className='mt-4 mb-3'>
                                            <Button as="a" link href={pdf} target="_blank" className={styles.buttonLink}>Реквизиты компании<i className="arrow"></i></Button>
                                        </div>
                                        {/* <Button secondary outline data-toggle="modal" data-target="#requestModal" className="mt-2">Связаться с нами</Button> */}
                                        <Button secondary outline as="a" href="mailto:info@transtechgroup.ru" className="mt-2">Связаться с нами</Button>
                                    </List>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Contacts;
